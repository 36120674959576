import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ForumPage = (props) => (
  <Layout  location={props.location}>
    <SEO title="Forum" />
    <h1>Forum</h1>

    <p>
        Forumul este deschis tuturor celor care doresc informații, clarificări sau care doresc să participe la acest proiect.
    </p>

    <p>
      Ne rezervăm dreptul de a nu accepta mesajele licențioase, pornografie, reclame sau mesaje care aduc ofense promotorilor și utilizatorilor acestui program.
    </p>

    <p>
      Vom încerca să răspundem tuturor întrebărilor în funcție de pertinență și de timpul disponibil. Proiectul este în continuă dezvoltare și cere încă multă dedicație din partea tuturor.
    </p>

    <p>
      Forumul poate fi accesat la adresa:  <a href="https://forum.analiza-gramaticala.ro/" target={"forum"}>https://forum.analiza-gramaticala.ro/</a>
    </p>


      {/*TODO install forum: https://codoforum.com/*/}

  </Layout>
)

export default ForumPage
